import { useState } from "react";
import { Box, Text } from "../../atoms";
import { Colors } from "../layout/Colors";

export const Pagination = (props) => {
  const {
    dataLength = 0,
    localStorageName = "",
    nextPage,
    prevPage,
    page,
    limit,
    changeLimit,
  } = props;

  const [showPaginationOptions, setShowPaginationOptions] = useState(false);

  const totalPages = Math.ceil(dataLength / limit);
  const activePage = <>{`${page}/${totalPages}`}</>;

  const handleSetPagination = (pagination) => {
    setShowPaginationOptions(false);
    changeLimit(pagination);
    if (localStorageName)
      window.localStorage.setItem(localStorageName, JSON.stringify(pagination));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 1.3,
      }}
    >
      <Text style={styles.text}>Exibindo</Text>
      <Box
        sx={{
          ...styles.paginationDropDownContainer,
          borderRadius: showPaginationOptions ? `8px 8px 0px 0px` : "8px",
        }}
        onClick={(event) => {
          event.preventDefault();
          setShowPaginationOptions(!showPaginationOptions);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Text style={{ ...styles.text }}>{limit}</Text>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box
              sx={{
                ...styles.dropDownIcon,
                backgroundImage: showPaginationOptions
                  ? `url('/icons/gray_arrow_up.png')`
                  : `url('/icons/gray_arrow_down.png')`,
              }}
            />
          </Box>
        </Box>
        {showPaginationOptions && (
          <Box
            sx={{
              ...styles.paginationDropDownOptionsContainer,
              borderRadius: `0px 0px 8px 8px`,
            }}
          >
            <Box
              sx={styles.paginationDropDownOptions}
              onClick={() => handleSetPagination(10)}
            >
              <Text style={styles.text}>10</Text>
            </Box>
            <Box
              sx={styles.paginationDropDownOptions}
              onClick={() => handleSetPagination(20)}
            >
              <Text style={styles.text}>20</Text>
            </Box>
            <Box
              sx={{ ...styles.paginationDropDownOptions, border: "none" }}
              onClick={() => handleSetPagination(50)}
            >
              <Text style={styles.text}>50</Text>
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <Text style={{ ...styles.text, whiteSpace: "nowrap" }}>
          por página.
        </Text>
      </Box>

      <Box style={{ display: "flex" }}>
        <Box sx={styles.paginationButtonContainer} onClick={() => prevPage()}>
          <Box sx={styles.paginationButton} />
        </Box>
        <Box
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={styles.text}>{activePage}</Text>
        </Box>
        <Box
          sx={styles.paginationButtonContainer}
          onClick={() => nextPage(totalPages)}
        >
          <Box
            sx={{
              ...styles.paginationButton,
              backgroundImage: `url('/icons/gray_arrow_up.png')`,
              transform: "rotate(90deg)",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  text: {
    fontSize: "14px",
  },
  paginationDropDownContainer: {
    position: "relative",
    width: 70,
    padding: 0.7,
    border: `1px solid #ddd`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: Colors.background,
      cursor: "pointer",
    },
  },
  paginationDropDownOptionsContainer: {
    display: "flex",
    position: "absolute",
    top: 32,
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#fff",
    border: `1px solid ${Colors.darkerBackground}`,
    zIndex: 99999,
  },
  paginationDropDownOptions: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: 0.7,
    paddingBottom: 0.7,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    "&:hover": {
      backgroundColor: Colors.background,
      cursor: "pointer",
    },
  },
  paginationButtonContainer: {
    position: "relative",
    height: 25,
    width: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  paginationButton: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    transform: "rotate(-90deg)",
  },
  dropDownIcon: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 12,
    height: 12,
  },
};
