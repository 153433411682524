import { useEffect, useState } from "react";
import { uid } from "../../../helpers/uid_generator";
import { usePagination } from "../../../hooks/usePagination";
import api from "../../../services/api";
import {
  ContentContainer,
  Pagination,
  SectionHeader,
} from "../../W3libComponents";
import { Box, Text } from "../../atoms";
import { PrimaryButton, SecondaryButton } from "../../organisms/button/Buttons";

function duration(params) {
  let duration = "";

  if (params?.days) params.duration += `${params.days}d `;
  if (params?.hours) params.duration += `${params.hours}h `;
  if (params?.minutes) params.duration += `${params.minutes}min`;

  return duration === "" ? "-" : duration.trim();
}

export const MacroReport = (props) => {
  const { context } = props;
  const { setLoading, alert } = context;

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statistics, setStatistics] = useState({});
  const [disableButton, setDisableButton] = useState(true);

  const { limit, page, nextPage, prevPage, changeLimit } = usePagination({
    customFilter1: "all",
  });

  const compareDates = () => {
    const from = new Date(startDate);
    const to = new Date(endDate);

    if (to - from < 0) return false;
    return true;
  };

  const loadReport = async (page = 1) => {
    setData([]);
    setLoading(true);
    try {
      const skip = (page - 1) * limit;
      const response = await api.get(
        `/v3/admin/macroreport?from=${startDate}&to=${endDate}&page=${page}&skip=${skip}&limit=${limit}`,
        { timeout: 0 }
      );
      if (response.status === 200) {
        setCount(response.data.content.count[0].total);
        setData(response.data.content.documents);
        setStatistics(response.data.statistics);
        return;
      }
      if (response.status === 204) {
        alert.info(
          "Sem registros",
          "Não encontramos nenhum contrato para o período selecionado."
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const exportToXml = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/v3/admin/macroreport-xml?from=${startDate}&to=${endDate}`
      );
      if (response.data.success) {
        window.alert("Documento será enviado para seu e-mail.");
      } else {
        window.alert(
          "Houve um erro ao tentar enviar o documento para o e-mail"
        );
      }
    } catch (error) {
      console.log(error);
      window.alert("Houve um erro ao tentar enviar o documento para o e-mail");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = `TuimBox Admin - Relatório Macro`;
  }, []);

  useEffect(() => {
    if (!!startDate && !!endDate) {
      if (compareDates()) {
        setDisableButton(false);
        return;
      } else {
        alert.error("A data final deve ser maior do que a data inicial.");
        setDisableButton(true);
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate) loadReport(page);
  }, [page, limit]);

  return (
    <>
      <style jsx global>
        {`
          .btnz {
            padding: 14px;
            font-size: 0.875rem;
            width: 200px;
            box-sizing: border-box;
            font-family: "Ubuntu";
            border-radius: 8px;
            border: none;
            color: #444;
            background-color: #d9d9d9;
          }
          .btnz:hover {
            background-color: #d9d9d9da;
            cursor: pointer;
          }
        `}
      </style>
      <SectionHeader
        title="Relatório Macro"
        subtitle={`Contratos: ${
          statistics?.totalContracts || "-"
        } | Pagamentos: ${statistics?.totalPayments || "-"}`}
      />
      <ContentContainer>
        <Box
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={styles.datePickerLabel}>De:</Text>
            <input
              type="date"
              value={startDate}
              onChange={({ target }) => setStartDate(target.value)}
              style={{
                width: "160px",
                padding: 9,
                borderRadius: 6,
                border: `1px solid #bbb`,
                fontFamily: "Ubuntu",
              }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={styles.datePickerLabel}>Até:</Text>
            <input
              type="date"
              value={endDate}
              onChange={({ target }) => setEndDate(target.value)}
              style={{
                width: "160px",
                padding: 9,
                borderRadius: 6,
                border: `1px solid #bbb`,
                fontFamily: "Ubuntu",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            <Box>
              <PrimaryButton
                text="Gerar Relatório"
                width="small"
                onClick={() => loadReport()}
                disabled={disableButton}
              />
            </Box>
            <Box>
              <SecondaryButton
                onClick={exportToXml}
                text="Exportar"
                width="small"
              />
            </Box>
          </Box>
          <Pagination
            dataLength={count}
            nextPage={nextPage}
            prevPage={prevPage}
            page={page}
            limit={limit}
            changeLimit={changeLimit}
          />
        </Box>

        {data?.length > 0 ? (
          <div style={{ maxWidth: "100%" }}>
            <Box
              style={{ overflow: "auto", borderRadius: 15, maxWidth: "100%" }}
            >
              <table id="macro_report">
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                  }}
                >
                  <tr>
                    <th
                      colSpan={7}
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Contrato
                    </th>
                    <th
                      colSpan={1}
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Filtro
                    </th>
                    <th
                      colSpan={1}
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Locker
                    </th>
                    <th
                      colSpan={2}
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Produto
                    </th>
                    <th
                      colSpan={14}
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Cliente
                    </th>
                    <th
                      colSpan={3}
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Pagamento Tuim
                    </th>
                    <th
                      colSpan={9}
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Pagamento Vindi
                    </th>
                    <th
                      colSpan={7}
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Avaliação
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      ID
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Tipo
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Cobrança
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Status
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Retirada
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Devolução
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Duração
                    </th>

                    {/* Filtro */}
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Tipo
                    </th>

                    {/* Locker */}
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Nome
                    </th>

                    {/* Produto */}
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      IV
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Nome
                    </th>

                    {/* Cliente */}
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Tipo
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      CPF
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Nº Passaporte
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      ID Cliente
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Contratos
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      RG
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Aceite Comun.
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Nome
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Nascimento
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Idade
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Sexo
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Celular
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      E-mail
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Data de Cadastro
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Data Assinatura
                    </th>

                    {/* Pagamento Tuim */}
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Valor Cobrado
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Valor do Item
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Tuimpoints
                    </th>

                    {/* Pagamento Vindi */}
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      ID Fatura
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Emissão
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Pagamento
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Atraso
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Status
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Tentativa
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Data
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Data-base
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#b1cccf",
                      }}
                    >
                      Produto Vindi
                    </th>

                    {/* Avaliação */}
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Limpeza
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Conservação
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Custo-Benefício
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Qualidade
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Mensagem
                    </th>
                    <th
                      style={{
                        ...styles.rowContent,
                        backgroundColor: "#87b5ba",
                      }}
                    >
                      Locatário Anterior
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data.map((item, index) => {
                    return (
                      <tr
                        key={`macro_report-${uid()}`}
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fff" : "#f1f1f1",
                        }}
                      >
                        <td style={styles.rowContent}>
                          {item?.contract?._id || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.contract?.period?.name ||
                            item?.contract?.interval ||
                            "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.tuimBoxPaymentData.type}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.contract?.status}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.contract?.createdAt || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.contract?.returnDate || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {duration(item?.contract?.duration)}
                        </td>

                        {/* Filtro */}
                        <td style={styles.rowContent}>{item?.filter || "-"}</td>

                        {/* Locker */}
                        <td style={styles.rowContent}>{item?.locker || "-"}</td>

                        {/* Produto */}
                        <td style={styles.rowContent}>
                          {item?.product?.iv || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.product?.name || "-"}
                        </td>

                        {/* Cliente */}
                        <td style={styles.rowContent}>
                          {item?.user?.recurrences > 1 ? "Sim" : "Não"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.cpf || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.passport || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user.idClient || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.recurrences}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.rg || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.newsLetter ? "Sim" : "Não"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.name || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user.dateBirth || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.age || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.sex || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.phone || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.email || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.registrationDate || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.user?.subscriptionCreationDate || "-"}
                        </td>

                        {/* Pagamento Tuim */}
                        <td style={styles.rowContent}>
                          {item?.tuimBoxPaymentData?.amount || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.tuimBoxPaymentData?.periodCost || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.tuimBoxPaymentData?.usedTuimPoints}
                        </td>

                        {/* Pagamento Vindi */}
                        <td style={styles.rowContent}>
                          {item?.vindiPaymentData?.billId || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.vindiPaymentData?.issuanceDate || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.vindiPaymentData?.paidAt || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {duration(item?.vindiPaymentData?.delay)}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.vindiPaymentData?.status}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.vindiPaymentData.tries || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.vindiPaymentData.date || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.vindiPaymentData?.baseDate || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.vindiPaymentData?.vindiProduct || "-"}
                        </td>

                        {/* Avaliação */}
                        <td style={styles.rowContent}>
                          {item?.evaluation?.cleaning || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.evaluation?.conservation || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.evaluation?.costBenefit || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.evaluation?.quality || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.evaluation?.observation || "-"}
                        </td>
                        <td style={styles.rowContent}>
                          {item?.evaluation?.previousUser || "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          </div>
        ) : (
          <></>
        )}
      </ContentContainer>
    </>
  );
};

const styles = {
  rowContent: {
    fontSize: "14px",
    textAlign: "center",
    padding: `12px`,
    whiteSpace: "nowrap",
  },
  datePickerLabel: {
    fontSize: 14,
    width: "50px",
  },
  regularText: {
    fontSize: 14,
  },
  excelButton: {
    backgroundColor: "#d9d9d9",
    color: "#444",
    borderRadius: 8,
    width: 200,
    border: "none",
    padding: 10,
    cursor: "pointer",
  },
};
